import React from "react";
import Paper from "@material-ui/core/Paper";
import useStyles from "./styles";
import { useTheme } from "@material-ui/core/styles";
import StackGrid from "react-stack-grid";
import Carousel, { Modal, ModalGateway } from "react-images";

const FILES = [
    { caption: "Agassi", fileName: `agassi.png` },
    { fileName: `aim.jpg` },
    { fileName: `ardor.png` },
    { fileName: `claddagh.png` },
    { fileName: `elephant.jpg` },
    { fileName: `elephants.png` },
    { fileName: `headache.jpg` },
    { fileName: `home.jpg` },
    { fileName: `img014.jpg` },
    { fileName: `img046.jpg` },
    { fileName: `innocent.jpg` },
    { fileName: `malala.jpg` },
    { fileName: `monkey.jpg` },
    { fileName: `old-woman-flower.png` },
    { fileName: `pandamonium.png` },
    { fileName: `rain-dance.png` },
    { fileName: `sinatra.png` },
    { fileName: `tears.jpg` },
];

const IMAGES = FILES.map((file) => ({
    ...file,
    src: `${process.env.PUBLIC_URL}/images/drawings/originals/${file.fileName}`,
}));

const THUMBS = FILES.map((file) => ({
    ...file,
    src: `${process.env.PUBLIC_URL}/images/drawings/thumbs/${file.fileName}`,
}));

const customStyles = {
    blanket: (base) => ({
        ...base,
        backgroundColor: "white",
        zIndex: 1200,
    }),
    dialog: (base) => ({
        ...base,
        // maxWidth: 600
    }),
    positioner: (base) => ({
        ...base,
        zIndex: 1400,
    }),
};

const Drawing = () => {
    const styles = useStyles();
    const theme = useTheme();

    const [currentImage, setCurrentImage] = React.useState(0);
    const [lightboxOpen, setLightboxOpen] = React.useState(false);

    const toggleLightbox = () => setLightboxOpen((open) => !open);

    return (
        <div className={styles.page}>
            <div className={styles.section}>
                <StackGrid columnWidth={160} gutterWidth={24} gutterHeight={24}>
                    {THUMBS.map((item, key) => (
                        <div
                            className={styles.thumbnail}
                            key={key}
                            elevation={3}
                            onClick={() => {
                                setCurrentImage(key);
                                setLightboxOpen(true);
                            }}
                            style={{
                                backgroundImage: `url(${item.src})`,
                                backgroundSize: "cover",
                                boxShadow: theme.shadows[2],
                                borderRadius: theme.spacing(0.5),
                            }}
                        >
                            <img
                                src={item.src}
                                width={"100%"}
                                style={{ visibility: "hidden" }}
                            />
                        </div>
                    ))}
                </StackGrid>
                <ModalGateway>
                    {lightboxOpen ? (
                        <Modal
                            onClose={toggleLightbox}
                            styles={customStyles}
                            allowFullscreen={false}
                        >
                            <Carousel
                                styles={{
                                    headerClose: (base) => ({
                                        ...base,
                                        color: "#666",
                                    }),
                                    navigationNext: (base) => ({
                                        ...base,
                                        color: "#666",
                                    }),
                                    navigationPrev: (base) => ({
                                        ...base,
                                        color: "#666",
                                    }),
                                    footerCount: (base) => ({
                                        ...base,
                                        color: "#666",
                                    }),
                                    footerCaption: (base) => ({
                                        ...base,
                                        color: "#666",
                                    }),
                                }}
                                views={IMAGES}
                                currentIndex={currentImage}
                                // frameProps={{ autoSize: "height" }}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        </div>
    );
};

export default Drawing;
