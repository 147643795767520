import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: "#1F1F1F",
        fontFamily: `'Lato', Helvetica, Arial, sans-serif`,
        overflowY: "hidden",
        transition: "opacity 2.5s"
      }
    },
    fabRoot: {
      position: "absolute",
      bottom: 24,
      right: 24,
      background: "#000",
      opacity: 0.8,
      color: "#fff",
      "&:hover": {
        background: "#000",
        opacity: 1
      },
      [theme.breakpoints.down("xs")]: {
        position: "fixed"
      }
    },
    fabIcon: {
      fontSize: "1.9rem"
    },
    chip: {
      borderRadius: 4,
      padding: "2px 8px",
      fontSize: 12,
      fontWeight: "bold",
      color: "white",
      verticalAlign: "baseline",
      whiteSpace: "nowrap",
      textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)",
      backgroundColor: "#999999",
      margin: "0 5px 5px 0"
    },
    root: {
      maxWidth: 760,
      height: "100vh",
      backgroundColor: "#fff",
      padding: 0,
      margin: "0px auto",
      overflowX: "hidden",
      position: "relative"
    },
    appBar: {
      background: "white",
      top: 9,
      height: 124,
      maxWidth: 760,
      position: 'relative',
      margin: 0,
      right: "auto",
    },
    border: {
      borderBottom: "1px solid #e8e8e8"
    },
    center: {
      display: "flex",
      justifyContent: "center"
    },
    separate: {
      marginBottom: 15
    },
    heading: {},
    secondaryHeading: {},
    section: {
      padding: "20px 80px",
      [theme.breakpoints.down("xs")]: {
        padding: 20
      }
    },
    tab: {
      color: "rgba(0, 0, 0, 0.87)",
      minWidth: "unset",
      fontSize: "0.75rem"
    },
    tabWrap: {
      [theme.breakpoints.down("xs")]: {
        padding: 6
      }
    },
    link: {
      textDecoration: "none !important",
      color: "#1A73E8",
      borderBottom: "1px dotted #1A73E8"
    },
    page: {
      // marginTop: 132,
      height: "calc(100vh - 132px)",
      width: "100%",
      overflowY: "auto"
    },
    thumbnail: {
      cursor: "pointer",
      transition: "transform .3s",
      "&:hover": {
        transform: "scale(1.2)"
      }
    },
    tabSelected: {
      color: "rgba(0, 0, 0, 0.87) !important"
    },
    tabsIndicator: {
      backgroundColor: "rgba(0, 0, 0, 0.87)"
    },
    tabsRoot: {
      borderBottom: "1px solid #e8e8e8"
    },
    title: {
      marginTop: 30,
      display: "flex",
      alignItems: 'baseline',
      justifyContent: "center",
      position: 'relative',
      whiteSpace: 'nowrap',
      width: '100%'
    },
    name: {
      color: "rgba(0,0,0, 0.87)",
      fontFamily: `'Domine', serif`,
      fontWeight: 700,
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.8rem"
      }
    },
    degree: {
      fontSize: "1rem",
      color: "rgba(0,0,0, 0.2)",
      marginLeft: 8,
      display: 'flex',
      // [theme.breakpoints.down("xs")]: {
      //   '& span': {
      //     display: 'none'
      //   }
      // }
    },
    radiographs: {
      position: "fixed",
      margin: "0 40px",
      width: "calc(100vw - 96px)",
      bottom: 0
    },
    sectionTitle: {
      textTransform: "uppercase",
      textAlign: "right",
      width: 85,
      fontSize: "0.87rem",
      color: "rgba(0,0,0,0.67)",
      marginBottom: 0,
      [theme.breakpoints.down("xs")]: {
        marginBottom: 10,
        width: "100%"
      }
    },
    radiographRoot: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      background: "black"
    },
    radiographGrid: {
      height: 200,
      width: "100%",
      marginLeft: 80,
      marginTop: 15,
      flexWrap: "nowrap",
      transform: "translateZ(0)"
    }
  })
);
