import React from "react";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import clamp from "lodash.clamp";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { animated, useTransition, useSpring } from "react-spring";

import Dentistry from "./Dentistry";
import Drawing from "./Drawing";
import Programming from "./Programming";

import tracking from "./tracking";
import useStyles from "./styles";

const pages = [
    ({ style, handleChangeIndex }) => (
        <animated.div
            style={{ position: "absolute", top: 0, width: "100%", ...style }}
        >
            <Dentistry handleChangeIndex={handleChangeIndex} />
        </animated.div>
    ),
    ({ style }) => (
        <animated.div
            style={{ position: "absolute", top: 0, width: "100%", ...style }}
        >
            <Programming />
        </animated.div>
    ),
    ({ style }) => (
        <animated.div
            style={{ position: "absolute", top: 0, width: "100%", ...style }}
        >
            <Drawing />
        </animated.div>
    ),
];

const theme = createMuiTheme({
    typography: {
        fontSize: 12,
    },
});

const App = () => {
    const classes = useStyles();

    const [value, setValue] = React.useState(0);
    const [direction, setDirection] = React.useState(1);

    const props = useSpring({
        fontSize: 28,
        opacity: 1,
        from: { fontSize: 64, opacity: 0 },
        config: {
            duration: 1250,
        },
    });

    const transitions = useTransition(value, (p) => p, {
        from: {
            opacity: 0,
            transform: `translate3d(${100 * direction}%,0,0)`,
        },
        enter: {
            opacity: 1,
            transform: "translate3d(0%,0,0)",
        },
        leave: {
            opacity: 0,
            transform: `translate3d(${-100 * direction}%,0,0)`,
        },
    });

    React.useEffect(() => {
        console.log(tracking)
        tracking.send({ hitType: 'pageview' });
        document.body.style.opacity = "1";
    }, []);

    const handleChange = (_, newValue) => {
        setValue(newValue);

        setDirection(clamp(newValue - value, -1, 1));
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box className={classes.root}>
                <AppBar
                    classes={{ root: classes.appBar }}
                    // position="static"
                    color="default"
                    elevation={0}
                >
                    <Typography component="div" className={classes.title}>
                        <animated.div className={classes.name} style={props}>
                            Saliya Seneviratne
                        </animated.div>
                        <Box className={classes.degree}>
                            <div>DMD</div>
                            <span>, </span>
                            <div style={{ margin: "0 4px" }}>MS</div>
                        </Box>
                    </Typography>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        classes={{
                            root: classes.tabsRoot,
                            indicator: classes.tabsIndicator,
                        }}
                    >
                        <Tab
                            label="Dentistry"
                            disableRipple={true}
                            classes={{
                                root: classes.tab,
                                selected: classes.tabSelected,
                                // labelContainer: classes.tabWrap
                            }}
                        />
                        <Tab
                            label="Programming"
                            disableRipple={true}
                            classes={{
                                root: classes.tab,
                                selected: classes.tabSelected,
                                // labelContainer: classes.tabWrap
                            }}
                        />
                        <Tab
                            label="Drawing"
                            disableRipple={true}
                            classes={{
                                root: classes.tab,
                                selected: classes.tabSelected,
                                // labelContainer: classes.tabWrap
                            }}
                        />
                    </Tabs>
                </AppBar>

                <Box position="relative">
                    {transitions.map(({ item, key, props }) => {
                        const Page = pages[item];
                        return (
                            <Page
                                key={key}
                                style={props}
                                handleChangeIndex={handleChangeIndex}
                            />
                        );
                    })}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default App;
