import React from "react";
import cx from "classnames";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";

const Dentistry = ({ handleChangeIndex }) => {
    const classes = useStyles();

    return (
        <div className={classes.page}>
            <div
                className={cx(classes.center, classes.border, classes.section)}
            >
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={3}>
                        <Typography
                            variant="subtitle1"
                            className={classes.sectionTitle}
                        >
                            Profile
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        <Typography variant="subtitle1" component="div">
                            <Box mb={2}>
                                Compassionate and ethical dental professional
                                driven by a fervor for cutting-edge methods in
                                digital dentistry. I value elegance above
                                adequacy, thoughtfulness over impulse, and the
                                beauty of the world as it reveals itself to us.
                                In my spare time, I enjoy (in no particular
                                order)
                            </Box>
                            <ul>
                                <li>
                                    <a
                                        href="#"
                                        onClick={() => handleChangeIndex(1)}
                                        className={classes.link}
                                    >
                                        Programming
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onClick={() => handleChangeIndex(2)}
                                        className={classes.link}
                                    >
                                        Drawing
                                    </a>
                                </li>
                                <li>Basketball</li>
                                <li>Cooking</li>
                            </ul>
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <div
                className={cx(classes.center, classes.border, classes.section)}
            >
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={3}>
                        <Typography
                            variant="subtitle1"
                            className={classes.sectionTitle}
                        >
                            Experience
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        <Box mb={3}>
                            <Typography component="div">
                                <Box fontWeight={600} component="span">
                                    Medgaus Dental Group
                                </Box>
                                <Box component="span" ml={1}>
                                    / Implant Dentistry
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <Box color="text.secondary">
                                    Monroeville, PA. Jan 2022 - Present
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <ul style={{ marginLeft: -20 }}>
                                    <li style={{ marginBottom: 8 }}>
                                    Surgical extractions, alveolectomy, socket
                                    and sinus grafting in preparation for
                                    implants
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                    Full-mouth and single implant placement
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                    Immediate-loaded implants with fixed and
                                    removable prosthetics
                                    </li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box mb={3}>
                            <Typography component="div">
                                <Box fontWeight={600} component="span">
                                    North American Dental Group
                                </Box>
                                <Box component="span" ml={1}>
                                    / General Dentistry
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <Box color="text.secondary">
                                    Poland, OH. Jan 2016 - Dec 2021
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <ul style={{ marginLeft: -20 }}>
                                    <li style={{ marginBottom: 8 }}>
                                        Provided high quality, comprehensive
                                        dental care in routine restorative,
                                        endodontics (anterior/molar), crown and
                                        bridge, removable prosthetics, implant
                                        placement and restoration, oral
                                        diagnosis, and treatment planning
                                    </li>
                                </ul>
                            </Typography>
                        </Box>
                        <Typography variant="caption" component="div">
                            (See more work experiences under{" "}
                            <a
                                href="#"
                                onClick={() => handleChangeIndex(1)}
                                className={classes.link}
                                style={{ color: "#000", borderColor: "#000" }}
                            >
                                Programming
                            </a>
                            )
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <div
                className={cx(classes.center, classes.border, classes.section)}
            >
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={3}>
                        <Typography
                            variant="subtitle1"
                            className={classes.sectionTitle}
                        >
                            Licensure
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        <Box mb={3}>
                            <Typography component="div">
                                <Box fontWeight={600} component="span">
                                    Pennsylvania Board of Dentistry
                                </Box>
                                <Box component="span" ml={1}>
                                    / DS040690
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <Box color="text.secondary">
                                    Exp 3/31/2025
                                </Box>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography component="div">
                                <Box fontWeight={600} component="span">
                                    Ohio State Dental Board
                                </Box>
                                <Box component="span" ml={1}>
                                    / 30.024691
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <Box color="text.secondary">Exp 12/31/2021</Box>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <div
                className={cx(classes.center, classes.border, classes.section)}
            >
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={3}>
                        <Typography
                            variant="subtitle1"
                            className={classes.sectionTitle}
                        >
                            Education
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        <Box mb={3}>
                            <Typography component="div">
                                <Box fontWeight={600} component="span">
                                    University of Pittsburgh School of Dental
                                    Medicine
                                </Box>
                                <Box component="span" ml={1}>
                                    / DMD
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <Box color="text.secondary">
                                    Pittsburgh, PA. August 2015
                                </Box>
                            </Typography>
                        </Box>
                        <Box mb={3}>
                            <Typography component="div">
                                <Box fontWeight={600} component="span">
                                    Georgetown University
                                </Box>
                                <Box component="span" ml={1}>
                                    / MS Physiology & Biophysics
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <Box color="text.secondary">
                                    Washington, D.C. May 2009
                                </Box>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography component="div">
                                <Box fontWeight={600} component="span">
                                    University of Pittsburgh
                                </Box>
                                <Box component="span" ml={1}>
                                    / BS Biology (Summa Cum Laude)
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <Box color="text.secondary">
                                    Pittsburgh, PA. May 2005
                                </Box>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <div className={cx(classes.center, classes.section)}>
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={3}>
                        <Typography
                            variant="subtitle1"
                            className={classes.sectionTitle}
                        >
                            Additional
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        <Box mb={3}>
                            <Typography component="div">
                                <Box fontWeight={600} component="span">
                                    Center for Advanced Dental Education
                                </Box>
                                <Box component="span" ml={1}>
                                    / Oral Plastic Surgery
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <Box color="text.secondary">
                                    Dallas, TX. Dec 2023
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <ul style={{ marginLeft: -20 }}>
                                    <li style={{ marginBottom: 8 }}>
                                    Microsurgical instrumentation and
                                    specialized suturing techniques
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                    Tunnel technique for treatment of single and
                                    multiple gingival recessions
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                    Management of soft tissue defects at implant
                                    sites
                                    </li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box mb={3}>
                            <Typography component="div">
                                <Box fontWeight={600} component="span">
                                    Resnik Implant Institute
                                </Box>
                                <Box component="span" ml={1}>
                                    / CBCT Boot Camp
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <Box color="text.secondary">
                                    Orlando, FL. Mar 2023
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <ul style={{ marginLeft: -20 }}>
                                    <li style={{ marginBottom: 8 }}>
                                    Advanced use of CBCT in oral implantology
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                    Dual scan technique, CBCT 3D printing
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                    Surgical template protocols
                                    </li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box mb={3}>
                            <Typography component="div">
                                <Box fontWeight={600} component="span">
                                    Glidewell Implant Symposium
                                </Box>
                                <Box component="span" ml={1}>
                                    / Implants: The Surgical Phase
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <Box color="text.secondary">
                                    Irvine, CA. Mar 2023
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <ul style={{ marginLeft: -20 }}>
                                    <li style={{ marginBottom: 8 }}>
                                    Guided surgery using stackable
                                    guide systems for immediate
                                    provisionalization in the full arch
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                    Tools, techniques and materials needed to
                                    predictably perform the sinus grafting
                                    procedure
                                    </li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box mb={3}>
                            <Typography component="div">
                                <Box fontWeight={600} component="span">
                                    Misch Implant Institute
                                </Box>
                                <Box component="span" ml={1}>
                                    / Surgical Program S1-S5
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <Box color="text.secondary">
                                    Orlando, FL. Feb 2017 - Sep 2017
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <ul style={{ marginLeft: -20 }}>
                                    <li style={{ marginBottom: 8 }}>
                                    CBCT treatment planning, biomechanics,
                                    socket grafting
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                    Implant placement and bone augmentation in
                                    compromised sites
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                    Treatment of posterior maxilla, lateral wall
                                    graft procedures, crestal approach grafting
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                    Immediate placement and loading, All-On-X
                                    </li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box mb={8}>
                            <Typography component="div">
                                <Box fontWeight={600} component="span">
                                    International Congress of Oral
                                    Implantologists
                                </Box>
                                <Box component="span" ml={1}>
                                    / Fellowship
                                </Box>
                            </Typography>
                            <Typography component="div">
                                <Box color="text.secondary">
                                    Houston, Tx. Feb 2020
                                </Box>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Dentistry;
