import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import useStyles from "./styles";
import cx from "classnames";

const TechnicalSection = ({ chips, isLast, title }) => {
    const classes = useStyles();

    return (
        <div
            style={{
                display: "flex",
                marginBottom: isLast ? 0 : 10,
                width: "100%",
            }}
        >
            <div style={{ width: 100 }}>{title}</div>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "calc(100% - 100px)",
                    width: "calc(100% - 100px)",
                }}
            >
                {chips.map((chip, idx) => (
                    <span key={idx} className={classes.chip}>
                        {chip}
                    </span>
                ))}
            </div>
        </div>
    );
};

const ExperienceSection = ({ bullets, chips, title, subtitle, caption }) => {
    const classes = useStyles();

    return (
        <Box mb={4}>
            <Typography component="div">
                <Box fontWeight={600} component="span">
                    {title}
                </Box>
                <Box component="span" ml={1}>
                    / {subtitle}
                </Box>
            </Typography>
            <Typography component="div">
                <Box color="text.secondary">{caption}</Box>
            </Typography>

            <Typography component="div">
                <ul style={{ marginLeft: -20 }}>
                    {bullets.map((bullet, idx) => (
                        <li key={idx} style={{ marginBottom: 8 }}>
                            {bullet}
                        </li>
                    ))}
                </ul>
            </Typography>
            <Box display="flex" flexWrap="wrap" width="500">
                {chips.map((chip, idx) => (
                    <Box key={idx} className={classes.chip}>
                        {chip}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

const Programming = () => {
    const classes = useStyles();

    return (
        <div className={classes.page}>
            <div
                className={cx(classes.center, classes.border, classes.section)}
            >
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={3}>
                        <Typography
                            variant="subtitle1"
                            className={classes.sectionTitle}
                        >
                            Overview
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        <Typography variant="subtitle1">
                            I enjoy computers, problems, and using the former to
                            figure out the latter. I have a background in the
                            natural sciences, but have spent much of my time
                            building and designing cool pieces of technology.
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <div
                className={cx(classes.center, classes.border, classes.section)}
            >
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={3}>
                        <Typography
                            variant="subtitle1"
                            className={classes.sectionTitle}
                        >
                            Technical
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <TechnicalSection
                                classes={classes}
                                title="Languages"
                                chips={[
                                    "JavaScript/Node.js",
                                    "Java",
                                    "Go",
                                    "Ruby",
                                    "Python",
                                    "PHP",
                                    "Shell",
                                ]}
                            />
                            <TechnicalSection
                                classes={classes}
                                title="Data Science"
                                chips={[
                                    "TensorFlow",
                                    "Machine Learning",
                                    "Neural Networks",
                                    "NumPy",
                                    "Pandas",
                                    "Keras",
                                ]}
                            />
                            <TechnicalSection
                                classes={classes}
                                title="Frameworks"
                                chips={[
                                    "Node.JS",
                                    "React",
                                    "React Native",
                                    "AngularJS",
                                    "Ruby on Rails",
                                    "Drupal",
                                    "CakePHP",
                                    "Backbone",
                                    "d3.js",
                                    "jQuery",
                                    "SASS",
                                    "Bootstrap",
                                ]}
                            />
                            <TechnicalSection
                                classes={classes}
                                title="Testing"
                                chips={[
                                    "TDD",
                                    "Jest",
                                    "Mocha",
                                    "RSpec",
                                    "Cucumber",
                                    "JUnit",
                                    "Selenium",
                                    "Ava",
                                    "Chai",
                                    "Enzyme",
                                    "Protractor",
                                ]}
                            />
                            <TechnicalSection
                                classes={classes}
                                title="DevOps"
                                chips={[
                                    "Docker",
                                    "Puppet",
                                    "Chef",
                                    "AWS (EC2 / ECS)",
                                    "Kubernetes",
                                ]}
                            />
                            <TechnicalSection
                                classes={classes}
                                title="Databases"
                                chips={[
                                    "MySQL",
                                    "PostgreSQL",
                                    "Redis",
                                    "CouchDB",
                                ]}
                            />
                            <TechnicalSection
                                classes={classes}
                                title="Graphics"
                                chips={["Photoshop", "InDesign", "Sketch"]}
                                isLast={true}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Box className={cx(classes.center, classes.section)} mb={4}>
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={3}>
                        <Typography
                            variant="subtitle1"
                            className={classes.sectionTitle}
                        >
                            Experience
                        </Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={9}>
                        <ExperienceSection
                            classes={classes}
                            title="Thinkful Inc"
                            subtitle="Software Development Mentor"
                            caption="Remote. 2013 - 2016"
                            bullets={[
                                "Mentored beginning and experienced software developers as they progressed through Thinkful's learning materials",
                                "Led weekly goal oriented 1-on-1 pair programming sessions with students",
                                "Reviewed code and shared personal best practices, workflows and tools with students",
                            ]}
                            chips={[
                                "Data Science",
                                "Node.JS",
                                "React",
                                "React Native",
                                "Angular",
                                "jQuery",
                                "Ruby on Rails",
                                "Mongo",
                                "Firebase",
                                "Mocha",
                                "Chai",
                                "Protractor",
                                "Jasmine",
                                "HTML5",
                                "CSS3",
                                "Javascript",
                                "UX design",
                            ]}
                        />
                        <ExperienceSection
                            classes={classes}
                            title="University of Pittsburgh Biomedical Informatics"
                            subtitle="Senior Developer"
                            caption="Pittsburgh, PA. 2009 - 2011"
                            bullets={[
                                "Developed NIDCR-funded FaceBase portal for researchers in the field of craniofacial genetics",
                                "Created technical infrastructures that supported scientific collaboration",
                                "Designed tools that managed data acquisition, indexing, and dissemination",
                            ]}
                            chips={[
                                "Symfony",
                                "Node.JS",
                                "d3.js",
                                "jQuery",
                                "RDF",
                                "Apache Solr",
                                "MySQL",
                                "Selenium",
                                "PHPUnit",
                            ]}
                        />
                        <ExperienceSection
                            classes={classes}
                            title="GiftCards.com (Omni Prepaid)"
                            subtitle="Lead Systems Engineer"
                            caption="Pittsburgh, PA. 2007 - 2009"
                            bullets={[
                                "Analyzed, designed, developed and supported a large-scale e-commerce application",
                                "Provided technical guidance and training to junior staff members",
                            ]}
                            chips={[
                                "CakePHP",
                                "Prototype.js",
                                "script.aculo.us",
                                "PostgreSQL",
                                "Nginx",
                                "SimpleTest",
                            ]}
                        />
                        <ExperienceSection
                            classes={classes}
                            title="Microbicide Trials Networks"
                            subtitle="Web Applications Architect"
                            caption="Pittsburgh, PA. 2005 - 2007"
                            bullets={[
                                "Designed database-driven applications that streamlined processes within a large, multi-center clinical trials network",
                                "Developed computer assisted self-interview (CASI) systems and survey processing tools",
                                "Web service integration of clinical trials information with the Division of AIDS Enterprise Information Management System",
                            ]}
                            chips={[
                                "PHP",
                                "jQuery",
                                "MySQL",
                                "XSLT",
                                "SOAP",
                                "Apache",
                                "HTTP",
                            ]}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default Programming;
